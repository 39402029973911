import React from "react";
import { useNavigate } from "react-router-dom";
import { checkSession ,removeSession} from '../redux/slice/authsession.js';
import { useDispatch } from 'react-redux';
import '../css/Usermangment.css'; // Import the CSS file
import Navbar from "./navbar";

const Usermangment = () => {
  const navigate = useNavigate();
  const dispatch  = useDispatch()

  const navigation = () => {
    navigate("/orderlist");
  };

  const addnaviagtion = () =>{
    navigate("/addressmangement");

  }
 
  const removelogout =async()=>{
      await dispatch(removeSession())
      navigate("/")
    
  }
  return (
    <div>
   <Navbar/>
    <div className="usermangment-container" style={{width:"500px", minHeight:"500px", marginTop:"40px"}} >
      <div onClick={navigation} className="usermangment-item orders">
        Orders
      </div>
      <div onClick={addnaviagtion}  className="usermangment-item address-management">
        Address Management
      </div>
      <div  onClick={removelogout} className="usermangment-item logout">
        Logout
      </div>
    </div>
  </div>
  );
};

export default Usermangment;
