// OfficeLocation.jsx

import React from 'react';
import { Typography, Box } from '@mui/material';

const OfficeLocation = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      <Typography variant="h4">Our Office Location</Typography>
      <Typography variant="body1">
        Factory Needs, B wing, Ground Floor, Shop No.2, Ekdantay Complex, Vill. Kasheli, Bhiwandi - 421302
      </Typography>
      
      {/* Google Maps iframe Embed */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23317.181055799365!2d72.8777!3d19.0760!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d7fa0cc3e5f1%3A0x6d4e9c49b2791537!2sYour+Office+Address!5e0!3m2!1sen!2sin!4v1675882874539!5m2!1sen!2sin"
        width="1500"
        height="450"
        style={{ border: '0' }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </Box>
  );
};

export default OfficeLocation;
