import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses, selectAddress } from '../redux/slice/address';
import { MapPin, Phone, Flag } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { checkSession ,removeSession} from '../redux/slice/authsession.js';
import '../css/AddressList.css'; // Import the CSS file
import Navbar from './navbar.js';

const AddressList = ({ showProceedButton = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [localAddresses, setLocalAddresses] = useState([]); // For localStorage fallback
  const { addresses, selectedAddress, status } = useSelector(state => state.addresses);
  const userId = useSelector(state => state.auth.userId); // Assuming you have auth state

  const Add_Adresss = () => {
    navigate('/add_address');
  };

  const handleEditAddress = (addressId) => {
    navigate(`/edit_address/${addressId}`);
  };


  const onProceed = ()=>{
  if(!showProceedButton){
    navigate("/paymentscreen")
  }else{
    navigate("/paymentscreenbuy")
  }
}

  useEffect(() => {
    const fetchSessionAndAddresses = async () => {
      try {
        const session = await dispatch(checkSession());
        console.log('from address session', session.payload);

        if (session.payload) {
          setIsLoggedIn(true);
          console.log('fetch_address');
          dispatch(fetchAddresses(userId));
        }
        // } else {
        //   // Load addresses from localStorage if not logged in
        // //   const storedAddresses = JSON.parse(localStorage.getItem('tempAddresses')) || [];
        // //   setLocalAddresses(storedAddresses);
        // }
      } catch (error) {
        console.error('Error fetching session or addresses:', error);
      }
    };

    fetchSessionAndAddresses(); // Call the async function
  }, [dispatch, userId]);

  const getAddressesToDisplay = () => {
    if (isLoggedIn) {
      return addresses; // Show addresses from Redux if logged in
    }
    return localAddresses; // Fallback to localStorage if not logged in
  };

  const addressesToDisplay = getAddressesToDisplay();

  if (status === 'loading') {
    return <div className="flex justify-center p-4">Loading addresses...</div>;
  }

//   if (addressesToDisplay.length === 0) {
//     return (
//       <div className="text-center p-4">
//         {/* <MapPin className="w-16 h-16 mx-auto text-yellow-600 mb-4" />
//         <h3 className="text-xl font-bold mb-2">No Addresses Saved</h3>
//         <p className="text-gray-600 mb-4">
//           Add your first address to manage your orders seamlessly!
//         </p> */}
//         <button onClick={Add_Adresss} className="add-address-button">
//           ADD ADDRESS
//         </button>
//       </div>
//     );
//   }

  return (
    <div className="space-y-4">
      <Navbar/>
      {addressesToDisplay.map((address, index) => (
        <div
          key={address.id || index} // Use index as fallback key for localStorage
          className={`address-card ${selectedAddress?.id === address.id ? 'selected' : ''}`}
          onClick={() => dispatch(selectAddress(address))}
        >
          <div className="address-header">
            <div className="address-info">
              <p className="font-bold text-green-900">
                {address.address_type?.toUpperCase() || 'SAVED ADDRESS'}
              </p>
              <div className="address-detail">
                <MapPin className="w-4 h-4 mr-2" />
                <span>{address.street_address}</span>
              </div>
              <div className="address-detail">
                <Flag className="w-4 h-4 mr-2" />
                <span>{`${address.city}, ${address.state}, ${address.country} - ${address.postal_code}`}</span>
              </div>
              <div className="address-detail">
                <Phone className="w-4 h-4 mr-2" />
                <span>{address.contact_number}</span>
              </div>
            </div>
            {selectedAddress?.id === address.id && (
              <div className="text-green-500">
                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>
          <div className="address-action">
        
 
          </div>
        </div>
      ))}

<button onClick={Add_Adresss} className="add-address-button">
          ADD ADDRESS
        </button>
    </div>
  );
};

export default AddressList;
