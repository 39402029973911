import React, { useState, useEffect } from 'react';
import apiClient from '../axios/axios';

const ProductPriceWidget = ({ subcategoryId }) => {
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        setLoading(true);
        const response = await apiClient.post(
          `/fetchdropdown/${subcategoryId}/min-max-price`,
          {},
          {
            headers: {
              'Content-Type': 'application/json; charset=UTF-8',
            },
          }
        );
        setPriceData(response.data);
      } catch (err) {
        setError(err.message || 'Failed to fetch price data');
      } finally {
        setLoading(false);
      }
    };

    fetchPriceData();
  }, [subcategoryId]);

  if (loading) {
    return <div>Loading...</div>; // Loading spinner or placeholder
  }

  if (error) {
    return <div>Error: {error}</div>; // Error message
  }

  if (!priceData || !priceData.final_prices) {
    return <div>No data available</div>; // No data message
  }

  const minPriceWithGst = priceData.final_prices.min_price_with_gst || 'N/A';
  const maxPriceWithGst = priceData.final_prices.max_price_with_gst || 'N/A';

  return (
    <div style={{ padding: '5px 0' }}>
      <span
        style={{
          fontSize: '16px',
          fontWeight: '500',
          color: '#000',
        }}
      >
        ₹{minPriceWithGst} - ₹{maxPriceWithGst}
      </span>
    </div>
  );
};

export default ProductPriceWidget;
