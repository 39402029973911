import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, FreeMode } from 'swiper/modules';
import Navbar from './navbar.js';
import Footer from './footer.js';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import ProductPriceWidget from './max_min.js';
import {
  fetchProductDetails,
  fetchDropdownData,
  updatePrice,
  setQuantity,
  setSelectedValues,
  updateDropDown
} from '../redux/slice/product.js';
import { addItem } from '../redux/slice/cartSlice.js';
import "../css/product.css";
import { addItemBuy } from '../redux/slice/buycart.js';
import ReachOutUs from './reachout.js';
import ContactUsWidget from './cannot.js';
import  Flowting from "./floating.js"

const ProductDetailScreen = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    product,
    dropdownData,
    selectedValues,
    quantity,
    productPrice,
    loading,
    error,
    product_name, 
  } = useSelector(state => state.product);

  useEffect(() => {
    console.log("Redux state -> product_name:", product_name);
  }, [product_name]);
  
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchProductDetails(id)),
        dispatch(fetchDropdownData(id))
      ]);
      setIsLoaded(true);
    };
    
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    return () => {
      if (thumbsSwiper) {
        thumbsSwiper.destroy();
        setThumbsSwiper(null);
      }
    };
  }, [thumbsSwiper]);

  const handleQuantityChange = (delta) => {
    const newQuantity = Math.max(1, quantity + delta);
    dispatch(setQuantity(newQuantity));
  };

  const handleDropdownChange = (name, value) => {
    console.log("🔄 Dropdown changed:", name, value);
    
    // Wait for state update before calling `updatePrice`
    const updatedSelectedValues = { ...selectedValues, [name]: value };
    
    dispatch(setSelectedValues(updatedSelectedValues));
    dispatch(updateDropDown({ id, name, selectedOptions: value }));
  
    // Ensure all options are selected before fetching price
    if (Object.values(updatedSelectedValues).every(v => v !== null)) {
      console.log("🛒 Updating price with:", updatedSelectedValues, "for product:", product.subcategory_name);
      dispatch(updatePrice({ id, selectedOptions: updatedSelectedValues }));
    }
  };
  

  const handleAddToCart = () => {
    if (productPrice && productPrice !== '0') {
      dispatch(addItem({
        productId: id,
        price: productPrice,
        selectedOptions: selectedValues,
        product_name: product.subcategory_name,
        quantity,
      }));
      alert('product is succefully add to cart')
    } else {
      alert('Please select all options first');
    }
  };

  const handleBuyNow = () => {
    if (productPrice && productPrice !== '0') {
      dispatch(addItemBuy({
        productId: id,
        price: productPrice,
        selectedOptions: selectedValues,
        quantity,
        product_name:  product.subcategory_name,
      }));
      navigate('/buyscreen');
    } else {
      alert('Please select all options first');
    }
  };

  if (loading || !isLoaded) return <div className="loading-spinner"><div className="spinner"></div></div>;
  if (error) return <div className="error-message">Error: {error}</div>;
  if (!product) return <div className="error-message">No product data available</div>;

  return (
    <div className="product-container">
      <ContactUsWidget/>
      <Navbar />
      {product.image_url?.length > 0 && (
        <div className="carousel-container">
          <Swiper
            className="product-swiper"
            modules={[Navigation, Thumbs]}
            navigation
            thumbs={{ swiper: thumbsSwiper }}
            spaceBetween={10}
          >
            {product.image_url.map((url, index) => (
              <SwiperSlide key={index}>
                <img src={url} alt={`Product ${index + 1}`} className="carousel-image-zoom" />
              </SwiperSlide>
            ))}
          </Swiper>

          <Swiper
            className="product-thumbnails"
            modules={[FreeMode, Navigation, Thumbs]}
            spaceBetween={5}
            slidesPerView={4}
            freeMode={true} // Enable FreeMode
            grabCursor={true} 
            watchSlidesProgress
            onSwiper={setThumbsSwiper}
          >
            {product.image_url.map((url, index) => (
              <SwiperSlide key={index}>
                <img src={url} alt={`Thumbnail ${index + 1}`} className="carousel-thumbnail" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div className="product-info">
      <h1 className="product-title">{product_name || "Product Title"}</h1>
        <ProductPriceWidget subcategoryId={product.subcategory_id} />
      </div>

      <div className="dropdowns-container">
        {dropdownData.map((dropdown) => (
          <div key={dropdown.name} className="dropdown-group">
            <label className="dropdown-label">{dropdown.name}</label>
            <select
              className="dropdown-select"
              value={selectedValues[dropdown.name] || ''}
              onChange={(e) => handleDropdownChange(dropdown.name, e.target.value)}
            >
              <option value="">Select an option</option>
              {dropdown.option_values.map((option) => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        ))}
      </div>

      <div className="quantity-container">
        <span className="quantity-label">Quantity:</span>
        <button className="quantity-button" onClick={() => handleQuantityChange(-1)}>-</button>
        <span className="quantity-value">{quantity}</span>
        <button className="quantity-button" onClick={() => handleQuantityChange(1)}>+</button>
      </div>

      <div className="price-display">
        <span className="price-label">Price including GST: </span>
        <span className="price-value">₹{parseFloat(productPrice || "0").toFixed(2)}</span>
      </div>


      <div className="action-buttons">
        <button className="action-button" onClick={handleAddToCart}>Add to Cart</button>
        <button className="action-button" onClick={handleBuyNow}>Buy Now</button>
      </div>
     
      <ReachOutUs/>
      <Flowting/>
      <Footer />
    </div>
  );
};

export default ProductDetailScreen;