import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import apiClient from '../axios/axios';
import Navbar from './navbar';
import "../css/paymentscreen.css"

const PaymentScreen = ({ orderId = 0, userId = 0, addressId = 0, cartId = 0 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const cartItems = useSelector(state => state.buycart.items);
  const address = useSelector((state) => state.addresses.selectedAddress);

  const orderAmount = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handlePaymentSuccess = async (paymentDetails) => {
    try {
      const verificationResponse = await apiClient.post('/verify-payment', {
        razorpay_payment_id: paymentDetails.razorpay_payment_id,
        razorpay_order_id: paymentDetails.razorpay_order_id,
        razorpay_signature: paymentDetails.razorpay_signature,
        order_id: orderId || cartId,
        user_id: userId,
        address_id: address.id,
        amount: orderAmount,
        type: orderId ? 'order' : 'cart',
        product: cartItems,
      });

      alert('Payment verified successfully!');
    } catch (error) {
      setPaymentError('An error occurred while verifying the payment.');
    }
  };

  const handlePaymentError = (error) => {
    setPaymentError('Payment failed. Please try again.');
  };

  const initiateRazorpayCheckout = async () => {
    setIsLoading(true);
    try {
      const response = await apiClient.post('/create-orders', {
        amount: orderAmount,
        currency: 'INR',
      });

      const options = {
        key: '', // Your Razorpay key
        order_id: response.data.id,
        amount: response.data.amount,
        name: 'FactoryNeeds',
        description: 'Order Payment',
        prefill: { contact: '1234567890', email: 'example@example.com' },
        handler: handlePaymentSuccess,
        modal: { escape: true, ondismiss: handlePaymentError },
      };
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error initiating Razorpay checkout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const showConfirmationDialog = () => {
    if (window.confirm(`You are about to initiate an offline payment process for INR ₹${orderAmount}. Proceed?`)) {
      submitOfflinePayment();
    }
  };

  const submitOfflinePayment = async () => {
    try {
      const response = await apiClient.post('/offline-payment', {
        amount: orderAmount,
        products: cartItems,
        Address_id: address.id,
      });

      alert('Offline payment submitted successfully!');
    } catch (error) {
      alert('Error occurred during offline payment submission.');
    }
  };

  return (
    <div className="payment-screen">
      <Navbar />
      <div className="order-summary">
        <h2>Order Summary</h2>
        <div className="order-items">
          {cartItems.map((item, index) => (
            <div key={index} className="cart-item">
              <h4 className="product-name">{item.product}</h4>
              <p>Price: ₹{item.price}</p>
              <p>Quantity: {item.quantity}</p>
              {Object.entries(item.selectedOptions).length > 0 && (
                <div className="options">
                  <h5 className="options-label">Options:</h5>
                  {Object.entries(item.selectedOptions).map(([key, value]) => (
                    <p key={key} className="option-item">{key}: {value}</p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="total-amount">
          <h3>Total Amount</h3>
          <p>₹{orderAmount}</p>
        </div>
      </div>

      <div className="payment-options">
        <h2>Payment Options</h2>
        <button
          onClick={initiateRazorpayCheckout}
          disabled={isLoading}
          className="payment-button"
        >
          {isLoading ? 'Processing Payment...' : `Pay Online ₹${orderAmount}`}
        </button>

        <div className="offline-payment">
          <h5>Bank Account Details</h5>
          <p>Account Name: Ganraj Traders</p>
          <p>Account Number: 60328635998</p>
          <p>Bank Name: Bank of Maharashtra</p>
          <p>IFSC Code: MAHB0001302</p>

          <button
            onClick={showConfirmationDialog}
            className="offline-payment-button"
          >
            Proceed with Offline Payment
          </button>
        </div>
      </div>

      {paymentError && <div className="error-message">{paymentError}</div>}
    </div>
  );
};

export default PaymentScreen;
