import React from 'react';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import '../css/footer.css'; // Optional: If you have custom CSS for the footer
import {  useNavigate } from 'react-router-dom'; 
const Footer = () => {
  const navigate = useNavigate()
  const handleLinkClick = (linkName, path="/") => {
    console.log(`Navigating to ${linkName}`);
  
    // Example condition: Check if the linkName is 'Store'
    if (linkName === 'Store') {
      // Perform custom actions for 'Store' link
      console.log('Store link clicked');
      // Example: Redirect to '/store' or do something specific
      navigate(path); // This will navigate to '/store' or the path passed
    } else if (linkName === 'RequestAQuote') {
      // Perform custom actions for 'Request A Quote' link
      console.log('Request A Quote link clicked');
      // Example: Maybe show a modal or alert, then navigate
      navigate("/contact_us"); // Navigate to '/quote'
    } else {
      // For other links, you can navigate normally
      console.log(`${linkName} link clicked`);
      navigate("/"); // General case, navigate to the passed path
    }
  };
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Working Hours</h4>
          <p>Mon-Fri: 10 AM – 6 PM</p>
          <p>Saturday: 10 AM – 4 PM</p>
          <p>Sunday: Closed</p>
        </div>

        <div className="footer-section">
          <h4>Office</h4>
          <p>
            Ganraj Traders<br /><br/>
            B wing, Ground Floor,<br /><br/>
            Shop No.2, Ekdantay Complex<br /><br/>
            Vill. Kasheli, Bhiwandi – 421302
          </p>
        </div>

        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: <a href="mailto:support@factoryneeds.in">support@factoryneeds.in</a></p>
          <p>Phone: <a href="tel:+918452855285">+91 84 5285 5285</a></p>
        </div>

        <div className="footer-section">
          <h4>Links</h4>
          <ul>
          
            <li><Link to="/contact_us" >Request A Quote</Link></li>
            <li><Link to="/contact_us">About Us</Link></li>
            <li><Link to="/contact_us">Blog</Link></li>
            <li><Link to="/contact_us" >Contact Us</Link></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Ganraj Traders. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
