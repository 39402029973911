import apiClient from "../axios/axios";


export const auth_exit_Request_Exit  =  (id) =>{ 
    console.log("/api/user_auth_exit_common")
    const data = apiClient.get(`/api/user_auth_exit_common`);
    return data
  
  }
  export const auth_Remove_Request_Exit = () =>{ 
    console.log("(`/api/user_auth_exit_common/remove")
    const data = apiClient.post(`/api/user_auth_exit_common/remove`);
    console.log(data)
    return data
  }