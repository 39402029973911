import React from 'react';
// import { QRCode } from 'qrcode.react';
import {QRCodeSVG} from 'qrcode.react';

const UpiQrCode = ({ amount }) => {
  // Default values for other UPI parameters
  const upiLink = `upi://pay?appid=inb_admin&tr=IND80a3b204125b4a95a642b2e2b3e9d49a&pa=ganrajtrader@mahb&pn=Ganraj Traders&am=${amount}&cu=INR`;

  return (
    <div>
      <h2>Scan to Pay via UPI</h2>
      
      {amount && (
        <div>
          <QRCodeSVG value={upiLink} size={256} bgColor="#ffffff" fgColor="#000000" />
        </div>
      )}
    </div>
  );
};

export default UpiQrCode;
