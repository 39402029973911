import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, Grid } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';
import Navbar from './navbar';
import OfficeLocation from './officelocation';
import Footer from './footer';
const ContactUs = () => {
  const [productName, setProductName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!productName || !name || !email || !phone || !message) {
      alert('Please fill in all fields.');
      return;
    }
    if (!/^[^@]+@[^@]+\.[^@]+$/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (!/^\+?[0-9]{7,15}$/.test(phone)) {
      alert('Please enter a valid phone number.');
      return;
    }

    const contact = '8452855285'; // Replace with your WhatsApp contact
    const text = `
*Product Inquiry:* ${productName}
*Name:* ${name}
*Email:* ${email}
*Phone:* ${phone}
*Message:* ${message}
    `;

    const whatsappUrl = `https://wa.me/${contact}?text=${encodeURIComponent(text)}`;
    window.open(whatsappUrl, '_blank'); // Open WhatsApp in a new tab

    // Clear form fields
    setProductName('');
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  return (
    <div style={{marginTop:"30px"}}>
    <Navbar/>
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      <Grid container spacing={4}>
        {/* Left Side - Information */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ padding: 4, display: 'flex', flexDirection: 'column' }}  style={{fontSize:"5.4rem", marginTop:"70px"}}>
            <Typography variant="h2" gutterBottom align="left" color="black" tyle={{fontSize:"5.4rem", marginBottom:"20px"}}>
              FactoryNeeds
            </Typography>
            <Typography variant='h2' align="left" tyle={{fontSize:"5rem", marginBottom:"70px"}}>
              Have questions? Get in touch!
            </Typography>
            <Typography variant="body2" align="left" sx={{ marginTop:"30px", fontSize:"20px" }}>
              Factory Needs is an online B2B & B2C platform offering technical guidance and supplying genuine market products at reasonable prices.
            </Typography>
            <Typography variant="h6" align="left" sx={{ marginTop: "40px" }}>
              Ganraj Traders
            </Typography>
            <Typography variant="body1" align="left">
              B wing, Ground Floor,
            </Typography>
            <Typography variant="body1" align="left">
              Shop No.2, Ekdantay Complex, Vill. Kasheli, Bhiwandi - 421302
            </Typography>
            <Typography variant="body1" align="left" sx={{ marginTop: 2 }}>
              <strong>Phone:</strong> +91 84 5285 5285
            </Typography>
            <Typography variant="body1" align="left">
              <strong>Email:</strong> support@factoryneeds.in
            </Typography>
          </Paper>
        </Grid>

        {/* Right Side - Contact Form */}
        <Grid item xs={12} md={6}>
          <Paper elevation={0} sx={{ padding: 4 }}>
            <Typography variant="h1" gutterBottom align="center" color="black" style={{fontSize:"5.4rem"}}>
              Contact Us
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap={3}>
                <TextField
                  label="Product Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'none', // Remove background
                      border: 'none', // Remove border
                      boxShadow: 'none', // Remove box shadow
                      '& input': {
                        // padding: 0, // Remove padding inside input
                        // margin: 0, // Remove margin
                         borderBottom: '2px solid black'
                      },
                      '& fieldset': {
                        border: 'none', // Remove the fieldset border
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none', // Optional: Remove label if you want it gone
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'none', // Remove background
                      border: 'none', // Remove border
                      boxShadow: 'none', // Remove box shadow
                      '& input': {
                        // padding: 0, // Remove padding inside input
                        // margin: 0, // Remove margin
                         borderBottom: '2px solid black'
                      },
                      '& fieldset': {
                        border: 'none', // Remove the fieldset border
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none', // Optional: Remove label if you want it gone
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'none', // Remove background
                      border: 'none', // Remove border
                      boxShadow: 'none', // Remove box shadow
                      '& input': {
                        // padding: 0, // Remove padding inside input
                        // margin: 0, // Remove margin
                         borderBottom: '2px solid black'
                      },
                      '& fieldset': {
                        border: 'none', // Remove the fieldset border
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none', // Optional: Remove label if you want it gone
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Phone Number"
                  variant="outlined"
                  fullWidth
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'none', // Remove background
                      border: 'none', // Remove border
                      boxShadow: 'none', // Remove box shadow
                      '& input': {
                        // padding: 0, // Remove padding inside input
                        // margin: 0, // Remove margin
                         borderBottom: '2px solid black'
                      },
                      '& fieldset': {
                        border: 'none', // Remove the fieldset border
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none', // Optional: Remove label if you want it gone
                      },
                    },
                  }}
                />
                <TextField
                  label="Your Message"
                  variant="outlined"
                  fullWidth
                  required
                  value={phone}
                  onChange={(e) => setMessage(e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'none', // Remove background
                      border: 'none', // Remove border
                      boxShadow: 'none', // Remove box shadow
                      '& input': {
                        // padding: 0, // Remove padding inside input
                        // margin: 0, // Remove margin
                         borderBottom: '2px solid black'
                      },
                      '& fieldset': {
                        border: 'none', // Remove the fieldset border
                      },
                      '& .MuiInputLabel-root': {
                        display: 'none', // Optional: Remove label if you want it gone
                      },
                    },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  startIcon={<WhatsApp />}
                  size="large"
                  sx={{ marginTop: 2, marginBottom:"60px" }}
                >
                  Submit via WhatsApp
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    <OfficeLocation/>
    <Footer/>
    </div>
  );
};

export default ContactUs;
