import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Add an item to the cart
    addItem: (state, action) => {
      const { productId, price, quantity, selectedOptions, product_name } = action.payload;
      const existingItem = state.items.find(item => item.productId === productId);
         console.log("this new item")
      if (existingItem) {
        // If the item already exists in the cart, update the quantity
        existingItem.quantity += quantity;
      } else {
        // If the item does not exist, add it to the cart
        console.log("item succefully added")
        state.items.push({ productId, price, quantity, selectedOptions, product_name});
      }
    },

    // Remove an item from the cart by index
    removeItem: (state, action) => {
      const productId = action.payload.productId; // Get the product ID from the payload
      console.log(productId);
      // Use `filter` to create a new array excluding the item with the given productId
      state.items = state.items.filter(item => item.productId !== productId);
      
    
    },

    // Update the quantity of an item in the cart
    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      console.log( productId, quantity )
      const item = state.items.find(item => item.productId === productId);
      // console.log(item)

      if (item) {
        // If the item exists, update the quantity or remove the item if quantity is 0
        if (quantity > 0) {
          item.quantity = quantity;
        } else {
          state.items = state.items.filter(item => item.productId !== productId);
        }
      }
    },

    // Clear all items from the cart
    clearCart: (state) => {
      console.log("clear cart")
      state.items = [];
    },

    // selectCartCount : (state) => 
    //   state.cart.items.reduce((total, item) => total + item.quantity, 0)
    
    
  },
});
export const selectCartCount = (state) =>
  state.cart.items.reduce((total, item) => total + item.quantity, 0);

export const { addItem, removeItem, updateQuantity, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
