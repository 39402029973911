import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/contact.css";

const ContactUsWidget = () => {
  const navigate = useNavigate();
  const [showWidget, setShowWidget] = useState(true);

  const handleContactUs = () => {
    // Navigate to the contact page
    navigate('/contact_us');
  };

  // Optional: Add animation effect to hide the widget after a few seconds
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowWidget(false); // Hide the widget after 5 seconds
  //   }, 5000);
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    showWidget && (
      <div className="contact-widget" onClick={handleContactUs} style={{height:"52px"}}>
        <div className="contact-widget-content">
          <h3 className="font-weight-bold animated-text" style={{fontSize:"16px"}}> 
            CAN'T FIND THE PRODUCT YOU ARE LOOKING FOR?
          </h3>
          <h3 className="text-muted animated-text" style={{fontSize:"16px"}}>
            CLICK HERE?
          </h3>
        </div>
      </div>
    )
  );
};

export default ContactUsWidget;
