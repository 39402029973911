import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/slice/auth.js";
import { checkSession,au} from "../redux/slice/authsession.js";
import "../css/login.css";
import {  useNavigate } from "react-router-dom";
import Navbar from "./navbar.js";
import { useLocation } from "react-router-dom";
const LoginForm = () => {
  const location = useLocation(); // Gets location object which contains state passed via navigate

  // Retrieve 'fromaddresscreen' state if available
  const fromaddresscreen = location.state?.fromaddresscreen;
  console.log(fromaddresscreen, "login---> from");
  const [email, setEmail] = useState("");
  const [message, setmessage] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.auth);
  // status = '';

  useEffect( () => {
    async  function checkSession_exits(){
      try{
        const data = await dispatch(checkSession());
        console.log(data.payload)
        if(data.payload){
           navigate("/users")
        }
      }catch(e){
         console.log(e);
      }
     }
     checkSession_exits()
  }, [dispatch]);

  const onClose = async () => {
    console.log("on cls");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await dispatch(login({ email, password }));
    //  console.log(error);
    //   console.log(result)
    var status = result.payload.login_status;
    // console.log(result.payload.status)
    if (status === "login_success") {
      alert("you have been successfully logged in");
      navigate(fromaddresscreen ? "/addresslist" : "/");
    } else if (status === "user_not_found") {
      alert("You have not signed up");
      // setMessage("You have not signed up");
      navigate("/signup", { state: { fromaddresscreen: fromaddresscreen } });
    } else if (status === "Invalid_password") {
      alert("Wrong password, please type again");
      setPassword("");
    }

 
  };
  const onForgot = () => {
    navigate("/forgotemail");
  };

  const onNew = () => {
    console.log(fromaddresscreen, "fromaddresscreen");
    if (fromaddresscreen) {
      console.log("fromaddres screen");
      console.log({ fromaddresscreen: fromaddresscreen });
      navigate("/signup", { state: { fromaddresscreen: fromaddresscreen } });
      // console.log
    } else {
      navigate("/signup");
    }
  };

  return (
    <div style={{marginTop:"30px"}}>
      <Navbar  />
      <div className="login-overlay">
        <div className="login-container">
          <h2 className="login-title">Login</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="form-input"
                required
              />
            </div>
            <div className="form-group password-field">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="form-input"
                required
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="toggle-password"
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <div>
              <div onClick={onForgot} style={{color:'indianred' , cursor:'pointer'}}>Forgot passoword ?</div>
            </div>
            <div className="form-actions">
            <button type="submit" disabled={loading} className="btn-primary">
                {loading ? "Loading..." : "Login"}
              </button>
              <div className="new-to-factoryneeds" onClick={onNew} >
                New To FactoryNeeds?&nbsp; &nbsp;<span style={{color:'indianred', cursor:'pointer'}}>Click Here</span>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
