import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import QRCode from './qr_code.js'
import { Button, Card, Col, Row, Alert, Spinner } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';
import apiClient from '../axios/axios';
import "../css/paymentscreen.css"
const PaymentScreen = ({ orderId = 0, userId = 0, addressId = 0, cartId = 0 }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState('');
  const cartItems = useSelector((state) => state.cart.items);
  const address  = useSelector((state)=>state.addresses.selectedAddress)
  console.log(address,cartItems)
  console.log(address.id,"_________________________Addddddddddddd")

  // Calculate total order amount
  const orderAmount = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const handlePaymentSuccess = async (paymentDetails) => {
    try {
       console.log('Payment successful------------------>:', paymentDetails);
      const verificationResponse = await apiClient.post('/verify-payment', {
        razorpay_payment_id: paymentDetails.razorpay_payment_id,
        razorpay_order_id: paymentDetails.razorpay_order_id,
        razorpay_signature: paymentDetails.razorpay_signature,
        order_id: orderId || cartId,
        user_id: userId,
        address_id:address.id ,
        amount: orderAmount,
        type: orderId ? 'order' : 'cart',
        product: cartItems,
      });

      console.log('Payment verification response:', verificationResponse.data);
      alert('Payment verified successfully!');
      // history.push('/order-success');
    } catch (error) {
      console.error('Error verifying payment:', error);
      setPaymentError('An error occurred while verifying the payment.');
    }
  };

  const handlePaymentError = (error) => {
    console.error('Payment failed:', error);
    setPaymentError('Payment failed. Please try again.');
  };

  const initiateRazorpayCheckout = async () => {
    console.log( { amount: orderAmount })
    setIsLoading(true);
    try {
      console.log(apiClient.defaults.baseURL);
      const response = await apiClient.post('/create-orders', { amount: orderAmount, 
        'currency': 'INR'});
        // console.log(apiClient.defaults.baseURL);
        
      //  console.log(options)
      const options = {
        key: 'rzp_live_4Be4WHMigayBxM', // Your Razorpay key
        order_id: response.data.id,
        amount: response.data.amount,
        name: 'FactoryNeeds',
        description: 'Order Payment',
        prefill: { contact: '1234567890', email: 'example@example.com' },
        handler: handlePaymentSuccess,
        modal: { escape: true, ondismiss: handlePaymentError },
      };
      console.log(options)
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error initiating Razorpay checkout:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const showConfirmationDialog = () => {
    if (
      window.confirm(
        `You are about to initiate an offline payment process for INR ₹${orderAmount}. Proceed?`
      )
    ) {
      submitOfflinePayment();
    }
  };

  const submitOfflinePayment = async () => {
    try {
      const response = await apiClient.post('/offline-payment', {
        amount: orderAmount,
        products: cartItems,
        Address_id:address.id ,
      });

      console.log('Offline payment submitted:', response.data);
      alert('Offline payment submitted successfully!');
      // history.push('/order-success');
    } catch (error) {
      console.error('Error submitting offline payment:', error);
      alert('Error occurred during offline payment submission.');
    }
  };

  return (
    <div className="payment-screen">
      <Card>
        <Card.Header>Order Summary</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {cartItems.map((item, index) => (
                <div key={index} className="cart-item">
                  <h5 className="product-name">{item.product}</h5>
                  <p>Price: ₹{item.price}</p>
                  <p>Quantity: {item.quantity}</p>
                  {Object.entries(item.selectedOptions).length > 0 && (
                    <div className="options">
                      <p className="options-label">Options:</p>
                      {Object.entries(item.selectedOptions).map(([key, value]) => (
                        <p key={key} className="option-item">
                          {key}: {value}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </Col>
            <Col>
              <h5>Total Amount</h5>
              <p>₹{orderAmount}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Payment Options</Card.Header>
        <Card.Body>
          <Button
            variant="primary"
            onClick={initiateRazorpayCheckout}
            disabled={isLoading}
            style={{ width: '100%', padding: '1rem' }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              `Pay Online ₹${orderAmount}`
            )}
          </Button>
          <div>
          <QRCode amount={orderAmount} />
          </div>
          <div className="offline-payment" style={{ marginTop: '20px' }}>
            <h5>Bank Account Details</h5>
            <p>Account Name: Ganraj Traders</p>
            <p>Account Number: 60328635998</p>
            <p>Bank Name: Bank of Maharashtra</p>
            <p>IFSC Code: MAHB0001302</p>

            <Button
              variant="secondary"
              onClick={showConfirmationDialog}
              style={{ width: '100%', padding: '1rem', marginTop: '20px' }}
            >
              Proceed with Offline Payment
            </Button>
          </div>
        </Card.Body>
      </Card>

      {paymentError && (
        <Alert variant="danger" style={{ marginTop: '20px' }}>
          {paymentError}
        </Alert>
      )}
    </div>
  );
};

export default PaymentScreen;
