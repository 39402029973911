import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../css/category.css';

const CategoryItemsComponent = () => {
  const [categoryItems, setCategoryItems] = useState([]);
  const [isLoadingCategoryItem, setIsLoadingCategoryItem] = useState(true);
  const navigate = useNavigate();



  const fetchCategoryItems = async () => {
    try {
      const response = await axios.get("https://rest.factoryneeds.in/m1-version/categories");
      if (response.status === 200) {
        const categoryItemsJson = response.data;
        const formattedItems = categoryItemsJson.map((item) => ({
          id: item.id.toString(),
          title: item.name.toString(),
          description: item.description.toString(),
          images: item.image_url.map((url) => url.toString()),
        }));
        setCategoryItems(formattedItems);
        setIsLoadingCategoryItem(false);
      } else {
        console.error("Failed to fetch category items");
      }
    } catch (error) {
      console.error("Error fetching category items:", error);
    }
  };

  useEffect(() => {
    fetchCategoryItems();
  }, []);

  const handleCardClick = (id) => {
    // Navigate to the subcategories component with the category ID
    navigate(`/subcategories/${id}`);
  };

  if (isLoadingCategoryItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className="grid-container">
  {categoryItems.map((item) => (
    <div key={item.id} className="category-card">
      <div
        className="card" style={{marginBottom:"20px"}}
        onClick={() => handleCardClick(item.id)} // Pass categoryId on click
      >
        <div>
          {item.images.map((url, index) => (
            <img key={index} src={url} alt={item.title} className="image" />
          ))}
        </div>
      </div >
      <h2 className="category-title">{item.title}</h2>
    </div>
  ))}
</div>

  );
};

export default CategoryItemsComponent;
