// BrandAdBanner.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import '../css/crousal.css'

const BrandAdBanner = () => {
  const [brandAds, setBrandAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    fetchBrandAds();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (brandAds.length) {
        setCurrentSlide((prev) => (prev + 1) % brandAds.length);
      }
    }, 5000);

    return () => clearInterval(timer);
  }, [brandAds.length]);

  const fetchBrandAds = async () => {
    try {
      const response = await axios.get("https://rest.factoryneeds.in/m1-version/brand-ads");
      const formattedData = response.data.map((ad) => ({
        image: ad.image_url,
        title: ad.title,
      }));
      setBrandAds(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching brand ads:", error);
      setError("Failed to load advertisements");
      setIsLoading(false);
    }
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % brandAds.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + brandAds.length) % brandAds.length);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (isLoading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="carousel-container-1">
      <div 
        className="carousel-track"
        style={{
          transform: `translateX(-${currentSlide * 100}%)`,
        }}
      >
        {brandAds.map((ad, index) => (
          <div key={index} className="carousel-slide">
            <div className="image-container">
              <img
                src={ad.image}
                alt={ad.title}
                onError={(e) => {
                  e.target.src = "/fallback-image.jpg";
                  e.target.onerror = null;
                }}
              />
              <div className="text-overlay">
                <h3>{ad.title}</h3>
              </div>
            </div>
          </div>
        ))}
      </div>

      <button className="nav-button prev" onClick={prevSlide}>
        &#8249;
      </button>
      <button className="nav-button next" onClick={nextSlide}>
        &#8250;
      </button>

      <div className="dots-container">
        {brandAds.map((_, index) => (
          <button
            key={index}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default BrandAdBanner;