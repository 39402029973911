import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const buycartSlice = createSlice({
  name: "buycart",
  initialState,
  reducers: {
    // Add an item to the cart
    addItemBuy: (state, action) => {
      const { productId, price, quantity, selectedOptions, product_name  } = action.payload;
      const existingItem = state.items.find(item => item.productId === productId);
         console.log("this new item")
      if (existingItem) {
        // If the item already exists in the cart, update the quantity
        existingItem.quantity += quantity;
      } else {
        // If the item does not exist, add it to the cart
        console.log("item succefully added")
        state.items.push({ productId, price, quantity, selectedOptions ,product_name});
      }
    },
    removeFirstItem: (state) => {
      if (state.items.length > 1) {
        state.items.shift();  // Removes the first item in the array
      }
    },
    // Remove an item from the cart by index
    removeItem: (state, action) => {
      const index = action.payload;
      if (index >= 0 && index < state.items.length) {
        state.items.splice(index, 1); // Remove the item at the specified index
      }
    },

    // Update the quantity of an item in the cart
    updateQuantity: (state, action) => {
      const { productId, quantity } = action.payload;
      console.log( productId, quantity )
      const item = state.items.find(item => item.productId === productId);
      // console.log(item)

      if (item) {
        // If the item exists, update the quantity or remove the item if quantity is 0
        if (quantity > 0) {
          item.quantity = quantity;
        } else {
          state.items = state.items.filter(item => item.productId !== productId);
        }
      }
    },
    cartempty:(state)=>{
      // state.items = state.items.shift()
      state.items = state.items.slice(1);
    },
    // Clear all items from the cart
    clearCart: (state) => {
      console.log("clear cart")
      state.items = [];
    },
  },
});

export const { addItemBuy, removeItem, updateQuantity, clearCart ,cartempty,removeFirstItem} = buycartSlice.actions;
export default buycartSlice.reducer;
