import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../axios/axios.js';

export const fetchProductDetails = createAsyncThunk(
  'product/fetchProductDetails',
  async (subcategoryId, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/categories/${subcategoryId}/getsubcategoriesbyproduct`);
      return response.data[0] || null;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch product details");
    }
  }
);

export const fetchDropdownData = createAsyncThunk(
  'product/fetchDropdownData',
  async (subcategoryId) => {
    const response = await apiClient.get(`/fetchdropdown/${subcategoryId}`);
    return response.data.result || [];
  }
);

export const updatePrice = createAsyncThunk(
  'product/updatePrice',
  async ({ id, selectedOptions }) => {
    try {
      const response = await apiClient.post(`/fetchdropdown/${id}/price`, {
        subcategory_id: id,
        selectedOptions,
      });
      return response.data.final_cost || '0';
    } catch (error) {
      throw error;
    }
  }
);

export const updateDropDown = createAsyncThunk(
  'product/updatedropdown',
  async ({ id, name, selectedOptions }) => {
    const response = await apiClient.post(`/updatedropdown/${id}/update`, {
      selectedOptions: { [name]: selectedOptions },
    });
    return response.data.mergedResult || [];
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState: {
    product: null,
    dropdownData: [],
    selectedValues: {},
    quantity: 1,
    productPrice: '',
    loading: true,
    error: null,
    product_name: '' // ✅ Ensure product_name is initialized
  },
  reducers: {
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    setSelectedValues: (state, action) => {
      state.selectedValues = { ...state.selectedValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload;
        state.product_name = action.payload?.subcategory_name || ""; // ✅ Assign product_name
      })
      .addCase(fetchProductDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error fetching product details";
      })
      .addCase(fetchDropdownData.fulfilled, (state, action) => {
        state.dropdownData = action.payload;
        state.selectedValues = action.payload.reduce((acc, dropdown) => {
          acc[dropdown.name] = null;
          return acc;
        }, {});
      })
      .addCase(updateDropDown.fulfilled, (state, action) => {
        state.dropdownData = action.payload;
      })
      .addCase(updatePrice.fulfilled, (state, action) => {
        state.productPrice = action.payload;
      });
  },
});

export const { setQuantity, setSelectedValues } = productSlice.actions;
export default productSlice.reducer;