import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WhatsApp } from '@mui/icons-material';
import '../css/flow.css';

const ChatWidget = () => {
  const navigate = useNavigate();
  const phoneNumber = "8452855285"; // Your WhatsApp contact number
  
  const openWhatsApp = () => {
    const message = 'Hello, I need help!';
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="chat-widget-container" onClick={openWhatsApp}>
      <div className="chat-widget">
        <WhatsApp sx={{ color: 'white', fontSize: 35 }} />
      </div>
    </div>
  );
};

export default ChatWidget;
