import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import apiClient from '../../axios/axios';

// Async thunks
export const fetchAddresses = createAsyncThunk(
  'addresses/fetchAddresses',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/addresses?user_id=${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveAddress = createAsyncThunk(
  'addresses/saveAddress',
  async ({ addressData, addressId }, { rejectWithValue }) => {
    try {
      const url = addressId ? `/addresses/${addressId}` : `/addresses`;
      const method = addressId ? 'put' : 'post';
      const response = await apiClient[method](url, addressData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveofflineAddress = createAsyncThunk(
  'addresses/saveAddressoffline',
  async (_, { rejectWithValue }) => {
    try {
      console.log("Attempting to save offline addresses...");
      
      // Retrieve the temporary addresses from localStorage
      const data = localStorage.getItem('tempAddresses');
      
      // Check if the data exists
      if (!data) {
        throw new Error("No temporary addresses found in localStorage.");
      }

      // Parse the retrieved data (assuming it is stored as JSON)
      const parsedData = JSON.parse(data);

      // Ensure the parsed data is valid and not empty
      if (!Array.isArray(parsedData) || parsedData.length === 0) {
        throw new Error("Invalid or empty data format in tempAddresses.");
      }

      // Define the API endpoint
      const url = "/addresses/send_addresses";

      // Send the data to the server using a POST request
      const response = await apiClient.post(url, parsedData);

      // Return the server's response
      return response.data;
    } catch (error) {
      console.error("Error occurred while saving offline addresses:", error);

      // Return the error using rejectWithValue for proper error handling
      return rejectWithValue(
        error.response?.data || error.message || "An unknown error occurred."
      );
    }
  }
);



const addressSlice = createSlice({
  name: 'addresses',
  initialState: {
    addresses: [],
    selectedAddress: null,
    status: 'idle',
    error: null,
    cached: [],
  },
  reducers: {
    selectAddress: (state, action) => {
      state.selectedAddress = action.payload;
    },
    cacheAddress: (state, action) => {
      state.cached.push(action.payload);
    },
    clearCachedAddresses: (state) => {
      state.cached = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddresses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addresses = action.payload;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(saveAddress.fulfilled, (state, action) => {
        const index = state.addresses.findIndex(addr => addr.id === action.payload.id);
        if (index !== -1) {
          state.addresses[index] = action.payload;
        } else {
          state.addresses.push(action.payload);
        }
      })

   . addCase(saveofflineAddress.pending, (state) => {
    state.status = 'saving';
  })
  .addCase(saveofflineAddress.fulfilled, (state, action) => {
    state.status = 'succeeded';
    console.log("Offline address saved:", action.payload);
  })
  .addCase(saveofflineAddress.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.payload || 'Failed to save offline address.';
  });

  },
});

export const { selectAddress, cacheAddress, clearCachedAddresses } = addressSlice.actions;
export default addressSlice.reducer;




