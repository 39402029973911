import React, { useState, useEffect } from "react";
import axios from "axios";
import { Menu, MenuItem, Button } from "@mui/material";  // Import MUI components
import { useNavigate } from "react-router-dom";

const CategoryDropdown = () => {
  const [categoryItems, setCategoryItems] = useState([]);
  const [isLoadingCategoryItem, setIsLoadingCategoryItem] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);  // Anchor element for dropdown menu
  const navigate = useNavigate();

  const fetchCategoryItems = async () => {
    try {
      const response = await axios.get("https://rest.factoryneeds.in/m1-version/categories");
      if (response.status === 200) {
        const categoryItemsJson = response.data;
        const formattedItems = categoryItemsJson.map((item) => ({
          id: item.id.toString(),
          title: item.name.toString(),
          description: item.description.toString(),
          images: item.image_url.map((url) => url.toString()),
        }));
        setCategoryItems(formattedItems);
        setIsLoadingCategoryItem(false);
      } else {
        console.error("Failed to fetch category items");
      }
    } catch (error) {
      console.error("Error fetching category items:", error);
    }
  };

  useEffect(() => {
    fetchCategoryItems();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);  // Open the dropdown menu
  };

  const handleClose = () => {
    setAnchorEl(null);  // Close the dropdown menu
  };

  const handleMenuItemClick = (id) => {
    // Navigate to the subcategories component with the category ID
    navigate(`/subcategories/${id}`);
    setAnchorEl(null);  // Close the dropdown after selection
  };

  if (isLoadingCategoryItem) {
    return <div>Loading...</div>;
  }

 
  return (
    <div>
      <Button
  onClick={handleClick}
  style={{
    backgroundColor: 'white',
    color: '#333',
    fontSize: '18px',
    padding:  '0px 15px',  // Removes any default padding
    border: 'none', // Removes the default border
    outline: 'none', // Removes focus outline
    paddingBottom: '10px'  // Custom padding bottom
    ,
    textTransform: 'none',
    fontWeight:500
  }}

>
  Categories &#x25BC;
</Button>
      {/* Dropdown menu */}
      <Menu
        id="category-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)} // Open the dropdown if anchorEl is set
        onClose={handleClose}
      >
        {categoryItems.map((item) => (
          <MenuItem key={item.id} onClick={() => handleMenuItemClick(item.id)}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CategoryDropdown;
