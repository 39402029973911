import axios from "axios";

const API_URL = "https://rest.factoryneeds.in/m1-version";

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Ensures cookies are sent with the request
  headers: {
    "Content-Type": "application/json",
  },
});


export default apiClient