import productReducer from '../redux/slice/product.js';
import { configureStore } from '@reduxjs/toolkit';
import addressReducer from '../redux/slice/address.js';
import authReducer  from '../redux/slice/auth.js'
import cartReducer from '../redux/slice/cartSlice.js'
import resetPasswordReducer from './slice/restpassword.js';
import buycartReducer  from "./slice/buycart.js"
import orderReducer from "./slice/ordelist.js"


export const store = configureStore({
  reducer: {
    product: productReducer,
    addresses: addressReducer,
    auth: authReducer,
    cart:cartReducer,
    buycart:buycartReducer,
    restpassword:resetPasswordReducer,
    order:orderReducer
  },
});