

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from '../../axios/axios';

const API_URL ="https://rest.factoryneeds.in/m1-version";

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/login`, { email, password }, {
        withCredentials: true
      });
      return response.data;
    } catch (error) {
        console.log(error)
      return rejectWithValue(error.response.data
      );
    }
  }
);

export const signup = createAsyncThunk(
  'auth/signup',
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData)
      const response = await apiClient.post(`/api/signup`, userData);
      console.log(response)
      return response.data;
    } catch (error) {
        console.log(error)
      return rejectWithValue(error.response.data);
    }
  }
);

export const otpverification = createAsyncThunk(
  'auth/signup',
  async (userData, { rejectWithValue }) => {
    try {
      console.log(userData)
      const response = await apiClient.post(`/api/signup`, userData);
      console.log(response)
      return response.data;
    } catch (error) {
        console.log(error)
      return rejectWithValue(error.response.data);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async ({ verificationCode }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/api/verify-email`, 
        { verification_code: verificationCode },
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  'auth/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/user/profile`, {
        withCredentials: true
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoggedIn: false,
    loading: false,
    error: null,
    verificationEmail: null,
    signupData:null
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
    },
    setVerificationEmail: (state, action) => {
      state.verificationEmail = action.payload;
    },
    storeSignupData: (state, action) => {
      state.signupData = action.payload; // Store signup form data
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.verificationEmail = action.payload.email;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  }
});

export const { logout, setVerificationEmail,storeSignupData } = authSlice.actions;
export default authSlice.reducer;

// src/components/auth/LoginForm.jsx


