// import apiClient from  "../axios/axios.js"
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../axios/axios.js';





export const  fetchOrderList= createAsyncThunk(
    '"order/fetchOrderDetails',
    async (_, { rejectWithValue }) => {
      try {
        const response = await apiClient.post(`/getAllPaymentsbothUserId`);
        console.log(response);
        return response.data.data
        // console.log({
        //   data: response.data,
        //   status: response.status,
        //   })
    //   return {
    //     data: response.data,
    //     status: response.status,
    //     }
      } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Failed to resend verification code.');
      }
    }
  );


  const orderSlice = createSlice({
    name: "order",
    initialState: {
      orders: [],
      loading: false,
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchOrderList.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchOrderList.fulfilled, (state, action) => {
          state.loading = false;
          state.orders = action.payload; // Update state with fetched data
        })
        .addCase(fetchOrderList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload; // Update state with error message
        });
    },
  });
  
  export default orderSlice.reducer;
  


