
import React from 'react';
import '../css/maintitel.css'
const MainTitel = () => {
  return (
    <div className="Titel">
        <h4 className='heading-smail-1'>Mordern Equipment</h4>
        <h1 className='heading-1'>Explore Our Extensive</h1>
        <h1 className='heading-2'>Store invertory</h1>
    </div>
  );
};

 export default MainTitel;
