import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../redux/slice/restpassword.js";
import { useNavigate } from "react-router-dom";
import "../css/ResetPasswordModal.css"; // Import updated CSS

const ResetPasswordModal = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector((state) => state.restpassword);

  const handleSubmit = async () => {
    const result = await dispatch(resetPassword({ email, otp, newPassword }));
    if (result.payload.status === 200) {
      navigate("/");
    }
  };

  return (
    <div className="modal-container" >
      <div className="modal">
        <h2 className="modal-title">Reset Your Password</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="modal-input"
        />
        <input
          type="text"
          placeholder="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="modal-input"
        />
        <input
          type="password"
          placeholder="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="modal-input"
        />

        {error && <p className="modal-error">{error}</p>}
        {success && <p className="modal-success">{success}</p>}

        <div className="modal-buttons">
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="modal-button modal-submit"
          >
            {loading ? "Resetting..." : "Reset Password"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
