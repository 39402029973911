import React from 'react';
import Navbar from './navbar';

const OrderCard = ({ order }) => {
  console.log({ order });

  const OrderStatus = {
    pending: 0,
    processing: 1,
    shipped: 2,
    outForDelivery: 3,
    delivered: 4,
    cancelled: 5,

    fromInt(value) {
      return Object.keys(OrderStatus).find(key => OrderStatus[key] === value) || 'pending';
    },

    getDisplayName(status) {
      switch (status) {
        case 'pending': return 'Pending';
        case 'processing': return 'Processing';
        case 'shipped': return 'Shipped';
        case 'outForDelivery': return 'Out for Delivery';
        case 'delivered': return 'Delivered';
        case 'cancelled': return 'Cancelled';
        default: return 'Unknown';
      }
    }
  };

  // Safely parse products and selectedOptions, handle case if they are not available
  const products = order.products ? JSON.parse(order.products) : [];

  return (
    <div>
      <div className="card border-primary mb-3" style={{ maxWidth: "24rem", marginLeft:"30px" }}>
        <div
          className="card-header text-white"
          style={{ backgroundColor: "#F9C600", color: "#000" }}
        >
          <h5>Order ID: {order.orderId}</h5>
        </div>
        <div className="card-body text-primary">
          <div className="mb-2"><strong>Payment Type:</strong> {order.paymentType}</div>
          <div className="mb-2"><strong>Amount:</strong> ₹{order.amount}</div>
          <div className="mb-2"><strong>Status:</strong> {OrderStatus.getDisplayName(order.status)}</div>
          <div className="mb-2"><strong>Created At:</strong> {new Date(order.orderCreatedAt).toLocaleString()}</div>

          <div>
            <strong>Products:</strong>
            <ul className="list-group mt-2">
              {products.length > 0 ? (
                products.map((product) => {
                  const selectedOptions = product.selectedOptions; // Get selected options for each product
                  return (
                    <li
                      key={product.productId}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <span>{product.productName} - ₹{product.price} x {product.quantity}</span>
                      <span className="badge bg-primary rounded-pill">₹{product.price * product.quantity}</span>
                      <div className="options">
                        {selectedOptions && Object.entries(selectedOptions).length > 0 && (
                          <ul>
                            {Object.entries(selectedOptions).map(([key, value]) => (
                              <li key={key}>{key}: {value}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="list-group-item">No products available</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
