import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {fetchOrderList} from '../redux/slice/ordelist'
import OrderCard  from "../componets/OrderCard"
import Navbar from "./navbar";
import Footer from "./footer";


const Orderlist = () => {
  const dispatch = useDispatch();
  const  [orders,setorders]= useState([])


  useEffect(()=>{
      const ApifetchOrderList  = async() =>{
           try  {
            const data =  await dispatch(fetchOrderList())
            setorders(data.payload)
            console.log(data)
           }catch(error){
            console.log(error)
           
           }
        
      }
      ApifetchOrderList()
  },[])

  return( 
    <div>
      <Navbar/>
    <h1 style={{ marginTop: "50px", marginLeft:"30px"}}>Orders List</h1>
    <button onClick={() => window.location.reload()} style={{ marginLeft:"30px"}}>Refresh</button>
    <div style={{ marginTop: "50px"}} className="col-md-6 mb-4" >
      {orders.map((order,index) => (
        <OrderCard key={index} order={order} />
      ))}
    </div>
    <Footer/>
  </div>
    
  )
  

};


export default Orderlist




