import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { signup ,storeSignupData} from '../redux/slice/auth.js';
import '../css/singup.css'

import { Navigate, useNavigate,useLocation } from 'react-router-dom';

const SignupForm = () => {
  const location = useLocation(); // Gets location object which contains state passed via navigate
  
  // Retrieve 'fromaddresscreen' state if available
  const fromaddresscreen = location.state?.fromaddresscreen;
    const [formData, setFormData] = useState({
      username: '',
      email: '',
      password: '',
      confirm_password: '',
      phoneNumber: '',
      companyName: '',
      gstNumber: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    dispatch(storeSignupData(formData));
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const result = await dispatch(signup(formData));
    
         navigate('/emailverfication_dialog', {state: { fromaddresscreen: fromaddresscreen } })

        
    };
    const onClose = async (e)=>{
    console.log("print")
    }
  
    return (
      <div className="signup-form-overlay">
        <div className="signup-form-container">
          <h2 className="signup-form-title">Sign Up</h2>
          <form onSubmit={handleSubmit} className="signup-form">
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Company Name</label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>GST Number</label>
              <input
                type="text"
                name="gstNumber"
                value={formData.gstNumber}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-actions">
              <button type="submit" className="btn-primary">
                Submit
              </button>
              <button type="button" className="btn-secondary" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default SignupForm;