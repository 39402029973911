
import './App.css';
import SubcategoriesComponent from './componets/SubcategoriesComponent.js';
import Product from './componets/prodcut.js'
import PaymentScreen from './componets/paymentscrren.js';
import PaymentScreenBuy from './componets/paymentscrrenbuy.js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from'./componets/home.js'
import CartScreen from './componets/cart.js';
import { store } from '../src/redux/store.js'; // Correct import for named export
import { Provider } from 'react-redux'; // To connect React with Redux
import LoginForm from './componets/login.js';
import SignupForm from './componets/singup.js';
import ForgotMainPassword from './componets/Forgotmainpassord.js';
import ForgotPasswordDialog from './componets/ForgotPasswordDialog.js'
import Rest from "./componets/ResetPasswordModal.js"
import EmailVerificationDialog from './componets/EmailVerificationDialog.js';
import Address from  './componets/addresslist.js'
import Add_Address from './componets/address.js'
import BuyScreen from './componets/buy.js';
import { Contact } from 'lucide-react';
import ContactUs from './componets/contact_us.js';
import UserManageMent from "./componets/userprofile.js"
import Orderlist from './componets/ordelist.js';
import Addressmangment from './componets/addressmangment.js'


function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <Router>
        <Routes>    
        <Route path="/" element={<Home />} /> 
          <Route path="/subcategories/:categoryId" element={<SubcategoriesComponent />} />
          <Route path="/subcategories/product/:id" element={<Product/>}/>
          <Route path="/cartscreen" element={<CartScreen/>}/>
          <Route path="/login" element={<LoginForm/>}/>
          <Route path="/signup" element={<SignupForm/>}/>
          <Route path ="/forgotemail" element ={<ForgotPasswordDialog/>}/>
         <Route path="/restEmaildialog" element={<Rest/>}/> 
          <Route path = "/addresslist" element={<Address/>}/>
          <Route path = "/add_address" element={<Add_Address/>}/>
          <Route path = "/emailverfication_dialog" element = {<EmailVerificationDialog/>}/>
         <Route path ="/paymentscreen" element = {<PaymentScreen/>}/>
         <Route path= "/buyscreen"  element ={<BuyScreen/>}/>
         <Route path ="/paymentscreenbuy" element = {<PaymentScreenBuy/>}/>
         <Route path = "/contact_us" element ={<ContactUs/>}/>
         <Route path =  "/users" element={<UserManageMent/>}/>
         <Route path = "/orderlist" element ={<Orderlist/>}/>
         <Route path = "/addressmangement" element= {<Addressmangment/>}/>
        </Routes>
      </Router>
    </div>
    </Provider>
  );
}

export default App;