import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeItem, updateQuantity, clearCart,selectCartCount } from '../redux/slice/cartSlice.js';
import '../css/cart.css'; // Import the CSS file
import Navbar from './navbar.js';


const CartScreen = ({ showAppBar = true, title, supar = false }) => {
  const cartCount = useSelector(selectCartCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItems = useSelector(state => state.cart.items);
  console.log(cartItems)
  
  const totalCartValue = cartItems.reduce((sum, item) => 
    sum + (parseFloat(item.price) * item.quantity), 0
  );

  return (
    <div className="cart-screen" style={{marginTop:"30px"}}>
      <Navbar/>
      {showAppBar && (
        <header className="header">
          <h1 className="title">{title}</h1>
        </header>
      )}

      <div className="summary">
        <div>
          <p className="total-label">Total Amount:</p>
          <p className="total-amount">₹{totalCartValue.toFixed(2)}</p>
        </div>
        <button
          onClick={() => navigate('/addresslist', {
            state: {
              from: '/cartscreen'  // ✅ Pass previous page as "/cart"
            }
          })}
          disabled={cartItems.length === 0}
          className="checkout-btn"
        >
          Checkout
        </button>
      </div>

      <div className="cart-items">
        {cartItems.length === 0 ? (
          <div className="empty-cart">
            <svg className="empty-cart-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            <p className="empty-cart-text">Your cart is empty</p>
          </div>
        ) : (
          cartItems.map((item, index) => (
            <div key={index} className="cart-item">
              <h3 className="product-name" style={{color:'black'}}>{item.product_name}</h3>
              
              {Object.entries(item.selectedOptions).length > 0 && (
                <div className="options">
                  <p className="options-label" style={{paddingLeft: 'inherit',fontWeight: '500', marginTop: '15px', marginBottom: '10px',}}>Options:</p>
                  {Object.entries(item.selectedOptions).map(([key, value]) => (
                    <p key={key} className="option-item">{key}: {value}</p>
                  ))}
                </div>
              )}

              <div className="item-footer">
                <p className="price">Price: ₹{item.price}</p>
                <div className="quantity-controls">
                  <button
                    onClick={() => item.quantity > 1 && 
                      dispatch(updateQuantity({productId:item.productId , quantity: item.quantity - 1 }))} 
                    className="quantity-btn"
                  >
                    <svg className="quantity-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                  <span className="quantity">{item.quantity}</span>
                  <button
                    onClick={() => dispatch(updateQuantity({ productId:item.productId, quantity: item.quantity + 1 }))}
                    className="quantity-btn"
                  >
                    <svg className="quantity-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => dispatch(removeItem({productId:item.productId}))}
                    className="remove-btn"
                  >
                    <svg className="remove-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CartScreen;
