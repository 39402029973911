import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Badge, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; 
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import '../css/navbar.css';
import CategoryDropdown from './categorydrop.js';
import { selectCartCount } from '../redux/slice/cartSlice';
import { checkSession, removeSession } from '../redux/slice/authsession.js';

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // ✅ Dynamically updated
  const cartCount = useSelector(selectCartCount);

  useEffect(() => {
    async function checkSession_exits() {
      try {
        const data = await dispatch(checkSession());
        console.log("Session Data:", data.payload);

        if (data.payload) {
          setIsLoggedIn(true); // ✅ Update state based on session
          // ✅ Redirect if session is valid
        } else {
          setIsLoggedIn(false); // ✅ Ensure logout updates the button
        }
      } catch (e) {
        console.error("Session Check Error:", e);
      }
    }
    checkSession_exits();
  }, [dispatch, navigate]);

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/login'); // Redirect to users page
  };

  const handleLogout = () => {
    dispatch(removeSession()); // Clear session from Redux 
    setIsLoggedIn(false);
    navigate('/'); // Redirect to home
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo" style={{ marginLeft: "20px" }}>
        <Link to="/">
          <img src="/logo.png" alt="MyLogo" className="logo-img" height={50} />
        </Link>
      </div>
      <div className="mobile-nav" onClick={toggleMobileMenu}>
        {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </div>

      <ul className={`nav-links ${isMobileMenuOpen ? "open" : ""}`} style={{ marginRight: "20px" }}>
        <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
        <li><Link to="/contact_us" onClick={toggleMobileMenu}>Contact</Link></li>
        <li><CategoryDropdown /></li>
        <li>
          {isLoggedIn ? (
            <a className="logout-link" onClick={handleLogout}>Logout</a>
            
          ) : (
            <a className="login-link" onClick={handleLogin}>Login</a>
          )}
        </li>
        
        <li>
          <div className='cart-space'>
            <Link to="/cartscreen" className="cart-link" onClick={toggleMobileMenu}>
              <IconButton color="inherit">
                <Badge badgeContent={cartCount} color="error">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
