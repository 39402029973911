import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/reachout.css";

const ReachOutUs = () => {
  const navigate = useNavigate();

  const handleContactUs = () => {
    navigate('/contact_us');
  };

  return (
    <div className="reachout-widget">
      <div className="reachout-content">
        <h3 className="reachout-heading">
          CAN'T FIND THE PRODUCT YOU'RE LOOKING FOR?
        </h3>
      </div>
      <button className="reachout-button" onClick={handleContactUs}>
        Reach Out
      </button>
    </div>
  );
};

export default ReachOutUs;
