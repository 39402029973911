import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import '../css/subcategory.css'
import  Navbar  from '../componets/navbar.js'
import Footer from "./footer.js";
import ProductPriceWidget from "./max_min.js";
import ContactUsWidget from "./cannot.js";
import ReachOutUs from "./reachout.js";
import  Flowting from "./floating.js"
const SubcategoriesComponent = () => {
  const { categoryId } = useParams(); // Get categoryId from the URL
  const [subcategories, setSubcategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

  useEffect(() => {
    // Fetch subcategories based on the categoryId
    const fetchSubcategories = async () => {
      try {
        const response = await axios.get(`https://rest.factoryneeds.in/m1-version/categories/${categoryId}/subcategories`);
        if (response.status === 200) {
          setSubcategories(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
        setIsLoading(false);
      }
    };

    fetchSubcategories();
  }, [categoryId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleCardClick = (id) => {
    console.log(id)
    // Navigate to the subcategories component with the category ID
    navigate(`/subcategories/product/${id}`);
  };
  return (
    <div>
      {/* <h1>Subcategories for Category ID: {categoryId}</h1> */}
      <ContactUsWidget/>
      <Navbar />
      <div className="subcategory-grid">
  {subcategories.map((subcategory) => (
    <div key={subcategory.subcategory_id}     onClick={() => handleCardClick(subcategory.subcategory_id)}        className="subcategory-card">
      {subcategory.image_url[0] && (
        <img
          src={subcategory.image_url[0]} // Get the first image URL
          alt={subcategory.subcategory_name}
          className="subcategory-image"
        />   
      )}
       <h2>{subcategory.subcategory_name}</h2>
       <ProductPriceWidget subcategoryId={subcategory.subcategory_id} />
    </div>
  ))}
</div>
<Flowting/>
<ReachOutUs/>
{/* <h1>This my hq</h1> */}
<Footer/>
    </div>
  );
};

export default SubcategoriesComponent;
