import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendForgotPasswordEmail } from '../redux/slice/restpassword';
import "../css/ForgotPasswordDialog.css"; // Import custom CSS
import {  useNavigate } from 'react-router-dom'; 
const ForgotPasswordDialog = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector((state) => state.restpassword);
  const data = useSelector((state) => state.restpassword);


  const handleSubmit = async() => {
      const result = await dispatch(sendForgotPasswordEmail(email));
      console.log(result)
       const status =result.payload.status
       if(status){
        navigate("/restEmaildialog")
       }else{
        navigate('/forgotemail')
       }
     
 


  };

  const handleCancel  = async ()=>{
    console.log("cancel")
    navigate('/')

  }
  return (
    <div className="">
      <h2 className="modal-title">Forgot Password</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="modal-input"
      />
      {/* {error && <p className="modal-error">{error}</p>}
      {success && <p className="modal-success">{success}</p>}
    */}
      <div className="modal-buttons">
        <button onClick={handleCancel} className="modal-button modal-cancel">
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          disabled={loading}
          className="modal-button modal-submit"
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordDialog;
