import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  resendVerificationCode,
  cancelVerificationCode,
  verifyEmail,
} from '../redux/slice/emailVerification';
import  {saveofflineAddress} from "../redux/slice/address"
import { useNavigate,useLocation } from "react-router-dom";
import Timer from './Timer'; // Import the Timer component
import '../css/EmailVerificationDialog.css';
import { CodeSquare } from 'lucide-react';

const EmailVerificationDialog = () => {
  const location = useLocation();
  const fromaddresscreen = location.state?.fromaddresscreen;
  const [verificationCode, setVerificationCode] = useState('');
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [showTimer, setShowTimer] = useState(false); // Manage timer visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Access email from redux state (ensure signupData contains email)
  const data = useSelector((state) => state.auth.signupData);
  const email = data?.email || "test";

  const handleResend = async () => {
    console.log("Resend verification code");
    const data = await dispatch(resendVerificationCode(email));
    console.log(data.payload.data.status);

    const status = data.payload.data.status;
    if (status === "verification_success") {
      alert('Please check your email. OTP has been successfully sent to you.');
      setIsResendDisabled(true);
      setShowTimer(true); // Show timer
    } else {
      navigate("/");
    }
  };

  const handleCancel = () => {
    console.log("Cancel verification");
    dispatch(cancelVerificationCode(email));
  };

  const handleSubmit = async () => {
    const data = await dispatch(verifyEmail({ email, verificationCode }));
    console.log(data.payload.data.status);

    const status = data.payload.data.status;
    // if (status === "verification_success") {
    //   console.log(fromaddresscreen,"verification_success")
    //   if(fromaddresscreen){
    //     const data = await dispatch(saveofflineAddress()).unwrap(); 
    //     console.log(data,"its data")
    //     navigate(fromaddresscreen ? "/addresslist" : "/");
    //   }else{
    //     navigate("/");
    //   }
    //   // navigate(fromaddresscreen ? "/addresslist" : "/");
    // } else if (status === "verification_not_success") {
    //   alert('Your OTP is wrong. Please type it again or click on the Resend button.');
    // }
    if (fromaddresscreen) {
      try {
        const data = await dispatch(saveofflineAddress()).unwrap();
        console.log(data, "its data");
        navigate("/addresslist");
      } catch (error) {
        console.error("Error saving offline address:", error);
        alert("Failed to save address. Please try again.");
      }
    } else {
      navigate("/");
    }
    
  };

  const loading = () => {
    return false; // Modify based on your Redux loading state
  };

  const handleTimerComplete = () => {
    setIsResendDisabled(false);
    setShowTimer(false); // Hide timer when countdown ends
  };

  return (
    <div className="dialog">
      <h2>Email Verification</h2>
      <p>Please check your email ({email}) for the verification code.</p>
      <input
        type="text"
        placeholder="Enter verification code"
        value={verificationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
      <button onClick={handleCancel} disabled={loading()}>Cancel</button>
      <button onClick={handleSubmit} disabled={loading()}>Submit</button>
      <button onClick={handleResend} disabled={isResendDisabled || loading()}>
        {isResendDisabled && showTimer ? (
          <Timer initialTime={240} onComplete={handleTimerComplete} />
        ) : (
          "Resend Code"
        )}
      </button>
    </div>
  );
};

export default EmailVerificationDialog;
