import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { saveAddress } from '../redux/slice/address';
import "../css/AddressFrom.css";
import Navbar from "./navbar.js";
import { checkSession } from '../redux/slice/authsession.js';
import { Navigate, useNavigate } from 'react-router-dom';

const AddressForm = ({ address, onSuccess }) => {
  const dispatch = useDispatch();
  const navigate  = useNavigate()
  const [formData, setFormData] = useState({
    streetAddress: '',
    postalCode: '',
    state: '',
    city: '',
    contactNumber: '',
    country: '',
  });
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track user login status
  const [allAddresses, setAllAddresses] = useState([]); // Track all addresses

  useEffect(() => {
    const fetchSessionStatus = async () => {
  // Ensure session is checked asynchronously
      const session =await dispatch(checkSession())
      console.log("from address session",session.payload)
      setIsLoggedIn(session.payload); // Update the login status based on session data
    };

    fetchSessionStatus(); // Call async function

    if (!isLoggedIn) {
      // Load all addresses from localStorage if not logged in
      const savedAddresses = JSON.parse(localStorage.getItem('tempAddresses')) || [];
      setAllAddresses(savedAddresses);

    } else {
      // Load address data from Redux store or server if logged in
      if (address) {
        setFormData({
          streetAddress: address.street_address || '',
          postalCode: address.postal_code || '',
          state: address.state || '',
          city: address.city || '',
          contactNumber: address.contact_number || '',
          country: address.country || '',
        });
      }
    }
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("from submiting",isLoggedIn)
    // Basic validation
    if (!formData.streetAddress || !formData.city || !formData.state || !formData.postalCode || !formData.contactNumber || !formData.country) {
      setError('All fields are required.');
      return;
    }

    const addressData = {
      street_address: formData.streetAddress,
      postal_code: formData.postalCode,
      state: formData.state,
      city: formData.city,
      contact_number: formData.contactNumber,
      country: formData.country,
      address_type: 'current',
    };

    if (!isLoggedIn) {
          console.log("you are not long in")
      const updatedAddresses = [...allAddresses, addressData];
      localStorage.setItem('tempAddresses', JSON.stringify(updatedAddresses));
      setAllAddresses(updatedAddresses);
      navigate("/login", { state: { fromaddresscreen: true } });
    }

    const resultAction = await dispatch(saveAddress({
      addressData,
      addressId: address?.id
    }));
console.log(resultAction)

    if (saveAddress.fulfilled.match(resultAction)) {
      // Clear localStorage once address is successfully saved
      if (!isLoggedIn) {
        localStorage.removeItem('tempAddresses');
      }
        navigate("/addresslist")
      onSuccess?.();
    } else {
      setError('Failed to save address. Please try again.');
    }
  };

  return (
    <div className='Address'>
      <Navbar />
      <div className='address_headline'>
        <h1>ADD YOUR ADDRESS</h1>
      </div>

      <form onSubmit={handleSubmit} className="address-form">
        <div>
          <input
            type="text"
            placeholder="Street Address"
            value={formData.streetAddress}
            onChange={(e) => setFormData({ ...formData, streetAddress: e.target.value })}
            className="input-field"
            required
          />
          <input
            type="text"
            placeholder="Postal Code"
            value={formData.postalCode}
            onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
            className="input-field"
            required
          />
          <input
            type="text"
            placeholder="State"
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            className="input-field"
            required
          />
          <input
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            className="input-field"
            required
          />
          <input
            type="tel"
            placeholder="Contact Number"
            value={formData.contactNumber}
            onChange={(e) => setFormData({ ...formData, contactNumber: e.target.value })}
            className="input-field"
            required
          />
          <input
            type="text"
            placeholder="Country"
            value={formData.country}
            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
            className="input-field"
            required
          />
        </div>

        {/* Display Error Message if any */}
        {error && <div className="error-message">{error}</div>}

        <button
          type="submit"
          className="submit-button"
        >
          {address ? 'Update Address' : 'Save Address'}
        </button>
      </form>

      {/* Displaying List of All Stored Addresses */}
    </div>
  );
};

export default AddressForm;
