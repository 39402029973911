import  Navbar from'./navbar.js';
import  Heroarea from'./heroarea.js'
import MainTitel from './maintitel.js';
import CategoryItemsComponent from './categorylist.js'
import Footer from './footer.js';
import { removeItem, updateQuantity, clearCart } from '../redux/slice/cartSlice.js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ContactUsWidget  from '../componets/cannot.js'
import ReachOutUs from './reachout.js';
import Flowting from "./floating.js"
import Modal from './model.js';
const Home = () => {
    const cartItems = useSelector(state => state.cart.items);
    console.log(cartItems)
return(
<div>
<ContactUsWidget/>
<Navbar />
<Heroarea />
<MainTitel />
<CategoryItemsComponent/>
<Flowting/>
<ReachOutUs/>
<Modal/>
<Footer/>
</div>
)
}

export default Home;
