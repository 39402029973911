import React, { useState, useEffect } from 'react';
import '../css/Modal.css';  // Ensure you have styling for the modal

const Modal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIosSafari, setIsIosSafari] = useState(false);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent;
    const isMobile = /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(userAgent);
    const isIos = /iPhone|iPad|iPod/i.test(userAgent);
    const isSafari = /Safari/i.test(userAgent) && !/Chrome/i.test(userAgent);

    // If user is on mobile and using Safari, we set isIosSafari to true
    if (isMobile && isIos && isSafari) {
      setIsIosSafari(true);
    }

    // If the user is on a mobile device, show the modal after 10 seconds
    if (isMobile) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);  // Open the modal after 10 seconds
      }, 5000);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, []);

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null; // Don't render the modal if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Experience Our Mobile App!</h2>
        <p>Take your experience to the next level with our mobile app.</p>
        <div className="modal-actions">
          {isIosSafari ? (
            // Link for iOS users
            <button onClick={() => window.location.href = 'https://link-to-your-ios-app-store'}>
              Download on the App Store (iOS)
            </button>
          ) : (
            // Link for Android or other users
            <button onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.factoryneeds.factoryneeds'}>
              Download Now
            </button>
          )}
          <button onClick={handleCloseModal}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
