// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchAddresses, selectAddress } from '../redux/slice/address';
// import { MapPin, Phone, Flag } from 'lucide-react';
// import { useNavigate } from 'react-router-dom';
// import { checkSession } from '../redux/slice/authsession.js';
// import Navbar from './navbar.js';

// const AddressList = ({ showProceedButton = true }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const [localAddresses, setLocalAddresses] = useState([]); // For localStorage fallback
//   const { addresses, selectedAddress, status } = useSelector(state => state.addresses);
//   const userId = useSelector(state => state.auth.userId); // Assuming you have auth state

//   const Add_Adresss = () => {
//     navigate('/add_address');
//   };

//   const handleEditAddress = (addressId) => {
//     navigate(`/edit_address/${addressId}`);
//   };

//   const onProceed = () => {
//     if (!showProceedButton) {
//       navigate("/paymentscreenbuy");
//     } else {
//       navigate("/paymentscreen");
//     }
//   };

// useEffect(() => {
//   const fetchSessionAndAddresses = async () => {
//     try {
//       const session = await dispatch(checkSession());
//       console.log('from address session', session.payload);

//       if (session.payload) {
//         setIsLoggedIn(true);
//         console.log('fetch_address');
//         dispatch(fetchAddresses(userId));
//       } else {
//         setIsLoggedIn(false);
//         navigate('/login'); // Redirect to login page if not logged in
//       }
//     } catch (error) {
//       console.error('Error fetching session or addresses:', error);
//     }
//   };

//   fetchSessionAndAddresses();
// }, [dispatch, userId, navigate]);


//   const getAddressesToDisplay = () => {
//     if (isLoggedIn) {
//       return addresses; 
//     }
//     return localAddresses; 
//   };

//   const addressesToDisplay = getAddressesToDisplay();

//   if (status === 'loading') {
//     return <div className="flex justify-center p-4">Loading addresses...</div>;
//   }

//   if (addressesToDisplay.length === 0) {
//     return (
//       <div className="text-center p-4">
//         <MapPin className="w-16 h-16 mx-auto text-yellow-600 mb-4" />
//         <h6 className="mb-2">No Addresses Saved</h6>
//         <p className="mb-4 text-gray-500">
//           Add your first address to manage your orders seamlessly!
//         </p>
//         <button className="btn-add-address" onClick={Add_Adresss}>
//           ADD ADDRESS
//         </button>
//       </div>
//     );
//   }

//   return (
//     <div className="space-y-4 px-4">
//       <Navbar />
//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//         <h1>SELECT THE ADDRESS </h1>
//         {addressesToDisplay.map((address, index) => (
//           <div
//             key={address.id || index}
//             className={`address-card ${selectedAddress?.id === address.id ? 'selected' : ''}`}
//             onClick={() => dispatch(selectAddress(address))}
//           >
//             <div className="address-content">
//               <h6 className="address-title">{address.address_type?.toUpperCase() || 'SAVED ADDRESS'}</h6>
//               <p className="address-detail">
//                 <MapPin className="w-4 h-4 inline-block mr-2" />
//                 {address.street_address}
//               </p>
//               <p className="address-detail">
//                 <Flag className="w-4 h-4 inline-block mr-2" />
//                 {`${address.city}, ${address.state}, ${address.country} - ${address.postal_code}`}
//               </p>
//               <p className="address-detail">
//                 <Phone className="w-4 h-4 inline-block mr-2" />
//                 {address.contact_number}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>

//       {showProceedButton && selectedAddress && (
//         <button className="btn-proceed" onClick={onProceed}>
//           PROCEED TO PAYMENT
//         </button>
//       )}
//     </div>
//   );
// };

// export default AddressList;





import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddresses, selectAddress } from '../redux/slice/address';
import { MapPin, Phone, Flag } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkSession } from '../redux/slice/authsession.js';
import Navbar from './navbar.js';

const AddressList = ({ showProceedButton = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [localAddresses, setLocalAddresses] = useState([]); // For localStorage fallback
  const { addresses, selectedAddress, status } = useSelector(state => state.addresses);
  const userId = useSelector(state => state.auth.userId); // Assuming you have auth state

  console.log("Location state:", location.state); // Debugging
  console.log("Previous Page:", location.state?.from); // Logs "/buyscreen" or "/cart"

  const Add_Adresss = () => {
    navigate('/add_address');
  };

  const handleEditAddress = (addressId) => {
    navigate(`/edit_address/${addressId}`);
  };

  const onProceed = () => {
    if (location.state?.from === "/buyscreen") {
      navigate("/paymentscreenbuy");
    } else if (location.state?.from === "/cartscreen") {
      navigate("/paymentscreen");
    } else {
      navigate("/paymentscreen"); // Default fallback
    }
  };

  useEffect(() => {
    const fetchSessionAndAddresses = async () => {
      try {
        const session = await dispatch(checkSession());

        if (session.payload) {
          setIsLoggedIn(true);
          dispatch(fetchAddresses(userId));
        } else {
          setIsLoggedIn(false);
          navigate('/login'); // Redirect to login page if not logged in
        }
      } catch (error) {
        console.error('Error fetching session or addresses:', error);
      }
    };

    fetchSessionAndAddresses();
  }, [dispatch, userId, navigate]);

  const getAddressesToDisplay = () => {
    if (isLoggedIn) {
      return addresses; 
    }
    return localAddresses; 
  };

  const addressesToDisplay = getAddressesToDisplay();

  if (status === 'loading') {
    return <div className="flex justify-center p-4">Loading addresses...</div>;
  }

  if (addressesToDisplay.length === 0) {
    return (
      <div className="text-center p-4">
        <MapPin className="w-16 h-16 mx-auto text-yellow-600 mb-4" />
        <h6 className="mb-2">No Addresses Saved</h6>
        <p className="mb-4 text-gray-500">
          Add your first address to manage your orders seamlessly!
        </p>
        <button className="btn-add-address" onClick={Add_Adresss}>
          ADD ADDRESS
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4 px-4">
      <Navbar />
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <h1>SELECT THE ADDRESS </h1>
        {addressesToDisplay.map((address, index) => (
          <div
            key={address.id || index}
            className={`address-card ${selectedAddress?.id === address.id ? 'selected' : ''}`}
            onClick={() => dispatch(selectAddress(address))}
          >
            <div className="address-content">
              <h6 className="address-title">{address.address_type?.toUpperCase() || 'SAVED ADDRESS'}</h6>
              <p className="address-detail">
                <MapPin className="w-4 h-4 inline-block mr-2" />
                {address.street_address}
              </p>
              <p className="address-detail">
                <Flag className="w-4 h-4 inline-block mr-2" />
                {`${address.city}, ${address.state}, ${address.country} - ${address.postal_code}`}
              </p>
              <p className="address-detail">
                <Phone className="w-4 h-4 inline-block mr-2" />
                {address.contact_number}
              </p>
            </div>
          </div>
        ))}
      </div>

      {showProceedButton && selectedAddress && (
        <button className="btn-proceed" onClick={onProceed}>
          PROCEED TO PAYMENT
        </button>
      )}
    </div>
  );
};

export default AddressList;
