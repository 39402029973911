import { createSlice, createAsyncThunk, combineSlices } from '@reduxjs/toolkit';
import axios from 'axios';
import apiClient from '../../axios/axios';


// Base API URL
const API_URL = "https://rest.factoryneeds.in/m1-version";

// Thunk to resend verification code
export const resendVerificationCode = createAsyncThunk(
  'emailVerification/resend',
  async (email, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/api/resend-email`, { email });
      console.log({
        data: response.data,
        status: response.status,
        })
    return {
      data: response.data,
      status: response.status,
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to resend verification code.');
    }
  }
);

// Thunk to cancel verification code
export const cancelVerificationCode = createAsyncThunk(
  'emailVerification/cancel',
  async (email, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/api/cancel_verification`, { email });
      return response.data.message;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to cancel verification.');
    }
  }
);

// Thunk to verify email
export const verifyEmail = createAsyncThunk(
  'emailVerification/verify',
  async ({ email, verificationCode }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/api/verify-email`, {
        // email,
        verification_code: verificationCode,
      });
      // console.log(`${API_URL}/api/verify-email`)
      // console.log(response)
      console.log({
       data: response.data,
       status: response.status,
       })
      return {
        data: response.data,
        status: response.status,
      }
      // return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to verify email.');
    }
  }
);

const emailVerificationSlice = createSlice({
  name: 'emailVerification',
  initialState: {
    loading: false,
    success: null,
    error: null,
  },
  reducers: {
    clearMessages(state) {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Resend Verification Code
      .addCase(resendVerificationCode.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(resendVerificationCode.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Cancel Verification Code
      .addCase(cancelVerificationCode.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(cancelVerificationCode.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(cancelVerificationCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Verify Email
      .addCase(verifyEmail.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload.message;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = emailVerificationSlice.actions;
export default emailVerificationSlice.reducer;
