// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { auth_exit_Request_Exit,auth_Remove_Request_Exit } from '../../service/api';

// Async Thunk for checking session
export const checkSession = createAsyncThunk('auth/checkSession', async (_, thunkAPI) => {
  try {
    const response = await auth_exit_Request_Exit(); // Endpoint to check session
     console.log(response.data.data);
    return response.data.data; // Assuming the session data is returned
  } catch (error) {
    return thunkAPI.rejectWithValue('No active session'); // Handle error (e.g., no session)
  }
});

export const removeSession = createAsyncThunk('auth/removeSession', async (_, thunkAPI) => {
  try {
    const response = await auth_Remove_Request_Exit(); // Endpoint to remove session
    console.log(response);
    return response.data; // Assuming the response contains success information
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to remove session'); // Handle error
  }
});
const authSlice = createSlice({
  name: 'auth_session',
  initialState: {
    user: null,
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Check session logic
      .addCase(checkSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(checkSession.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.error = null;
      })
      .addCase(checkSession.rejected, (state, action) => {
        state.status = 'failed';
        state.user = null;
        state.error = action.payload;
      })
      .addCase(removeSession.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeSession.fulfilled, (state) => {
        state.status = 'succeeded';
        state.user = null; // Clear user data after session removal
        state.error = null;
      })
      .addCase(removeSession.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
